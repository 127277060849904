<template>
  <div class="py-5">
    <div class="pb-5 my-4">
      <b-card-title class="pb-2 mb-0 text-med-18 text-font-main">
        {{ $t('common.restore_password') }}
      </b-card-title>
      <b-card-text class="mt-2 text-reg-14 text-font-secondary">
        {{ $t('common.enter_registered_email') }}
      </b-card-text>
    </div>
    <wameed-form @onSubmit="login">
      <!-- form -->
      <div  slot-scope="{ invalid }">
        <!-- email -->
        <div class="pt-1">
          <wameed-text-input
              id="login-email"
              v-model="form.email"
              :label="$t('common.email')"
              field-classes="pb-1 login-inputs"
              input-classes="text-reg-14 "
              name="email"
              placeholder="john@example.com"
              prepen-icon="email-icon"
              rules="required|email"
          />

          <!-- submit buttons -->
          <wameed-btn
              :classes="' text-reg-14 text-white d-flex align-items-center rounded-10  justify-content-center my-2'"
              :disabled="invalid"
              :title="$t('common.send')"
              block
              type="submit"
              variant="main"
          />
        </div>
      </div>
    </wameed-form>
  </div>
</template>

<script>

import {WameedBtn, WameedForm, WameedTextInput} from 'wameed-ui/dist/wameed-ui.esm';

export default {

  components: {
    WameedTextInput,
    WameedForm,
    WameedBtn,
  },
  data() {
    return {
      form: {
        email: '',
      },
    };
  },

  methods: {
    login() {
      this.$store.dispatch('auth/forgetPassword', this.form);
    },
  },
};
</script>
